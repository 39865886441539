// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggested-response {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.suggested-response:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateX(4px);
}
.suggested-response .response-number {
  background: rgba(255, 255, 255, 0.1);
  color: #888;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 500;
}
.suggested-response .response-text {
  color: #fff;
  line-height: 1.4;
  padding-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/SuggestedResponse.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,kBAAA;EACA,qCAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACF;AACE;EACE,qCAAA;EACA,0BAAA;AACJ;AAEE;EACE,oCAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AADJ","sourcesContent":[".suggested-response {\n  display: flex;\n  align-items: flex-start;\n  gap: 12px;\n  padding: 12px;\n  margin-bottom: 8px;\n  background: rgba(255, 255, 255, 0.05);\n  border-radius: 8px;\n  transition: all 0.2s ease;\n  cursor: pointer;\n\n  &:hover {\n    background: rgba(255, 255, 255, 0.08);\n    transform: translateX(4px);\n  }\n\n  .response-number {\n    background: rgba(255, 255, 255, 0.1);\n    color: #888;\n    min-width: 24px;\n    height: 24px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 0.9em;\n    font-weight: 500;\n  }\n\n  .response-text {\n    color: #fff;\n    line-height: 1.4;\n    padding-top: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../LandingPage/LandingPage.scss';

const Imprint = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <div className="landing-page">
      <div className="landing-page__gradient-bg" />

      <header className="landing-page__header">
        <div className="landing-page__header-container">
          <div className="landing-page__header-logo group">
            <Link to="/">
              <img src="/images/logo.png" alt="AI Voice Dating Simulator Logo" className="logo-image" />
            </Link>
            <h1>Dating Voice Simulator</h1>
          </div>
        </div>
      </header>

      <motion.main 
        className="landing-page__main"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <section className="content-section">
          <h2>Legal Notice (Impressum)</h2>
          
          <div className="content-block">
            <h3>Information according to § 5 TMG</h3>
            <address>
              Filedesign Informatik<br />
              Holeeholzweg 69<br />
              CH-4102 Binningen<br />
              Switzerland
            </address>
          </div>

          <div className="content-block">
            <h3>Contact</h3>
            <p>
              Phone: +41 61 422 oo oo<br />
              Email: kontakt [at] filedesign [dot] com
            </p>
          </div>

          <div className="content-block">
            <h3>Responsible for Content</h3>
            <p>Filedesign Informatik<br />
            Holeeholzweg 69<br />
            CH-4102 Binningen</p>
          </div>

          <div className="content-block">
            <h3>EU Representative according to Art. 27 GDPR</h3>
            <p>For users from the EU/EEA, our EU representative can be contacted at:<br />
            [EU Representative Details]</p>
          </div>
        </section>
      </motion.main>

      <footer className="landing-page__footer">
        <div className="footer-content">
          <div className="footer-info">
            <div className="info-section">
              <div className="legal-links">
                <Link to="/">Home</Link> • <Link to="/imprint">Imprint</Link> • <Link to="/privacy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Imprint;

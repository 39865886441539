import React from 'react';
import './SuggestedResponse.scss';

interface Props {
  index: number;
  response: string;
}

export const SuggestedResponse: React.FC<Props> = ({ index, response }) => {
  return (
    <div className="suggested-response">
      <div className="response-number">{index + 1}</div>
      <div className="response-text">{response}</div>
    </div>
  );
};

export default SuggestedResponse;

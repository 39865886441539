const USE_LOCAL_RELAY_SERVER_URL: string | undefined = void 0;

import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';
import { WingmanAnalysis } from '../components/WingmanAnalysis';

import { X, Mic } from 'react-feather';
import { Button } from '../components/button/Button';

import './VoiceChat.scss';
import config from '../config';

type Props = {
  scrapedContent: string;
};

interface AnalysisResult {
  nextResponses: string[];
  confidence: number;
}

const ANALYSIS_INTERVAL = 30000; // 30 seconds in milliseconds
const MIN_MESSAGES_FOR_HIGH_CONFIDENCE = 4; // Minimum messages needed for high confidence scores
const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 5000; // 5 seconds

// Analysis status messages
const ANALYSIS_MESSAGES = {
  UNAVAILABLE: "The conversation analysis system is temporarily unavailable. The analysis will automatically resume once the service is restored. Please continue your conversation naturally.",
  API_ERROR: "Unable to reach the analysis service. Retrying in 5 seconds...",
  RATE_LIMIT: "Analysis rate limit exceeded. Will retry shortly...",
  NETWORK_ERROR: "Network connection issue detected. Attempting to reconnect...",
};

// Simple function to get API key
const getApiKey = () => {
  const key = process.env.REACT_APP_OPENAI_API_KEY || '';
  return key;
};

// Random voice selection
const getRandomVoice = () => {
  const voices = ['coral', 'sage', 'shimmer', 'alloy'] as const;
  return voices[Math.floor(Math.random() * voices.length)];
};

export const VoiceChat: React.FC<Props> = ({ scrapedContent }) => {
  // Set chat hidden by default
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [audioContextInitialized, setAudioContextInitialized] = useState(false);
  const [isWingmanEnabled, setIsWingmanEnabled] = useState(true);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);
  const [conversationDuration, setConversationDuration] = useState('0m 0s');
  const analysisIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const itemsRef = useRef<ItemType[]>([]);
  const [conversationStartTime, setConversationStartTime] = useState<Date | null>(null);
  const retryAttemptsRef = useRef(0);
  const hasFirstResponseRef = useRef(false);

  const instructions = `SYSTEM SETTINGS:
------
INSTRUCTIONS:
Were going to simulate a conversation. Youll play the role of a girl, so youll have a typical Swiss Name. Youll choose and stay in one of the Myers-Briggs personalities. Youre a woman being approached by a stranger (me) in a real-world setting like a café or a park. Initially, youre skeptical and unsure if you want to engage, but you have a cheeky and humorous side. Youre open to meeting someone new but are cautious due to past experiences. You speak English.

Use natural, conversational language, including pauses, hesitations, and casual expressions.
Internally, you have a rating or trust level for the person approaching you. With each interaction, this rating can go up or down based on how the conversation progresses.
After four exchanges, naturally ask me why Im approaching you. When I answer, accept my explanation in a way that feels genuine.
Do not ask me about my job
Keep an eye on typical red flags from a man and update your trust level.
At the beginning keep short and bit skeptical. 
`;

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: getApiKey(),
      dangerouslyAllowAPIKeyInBrowser: true
    })
  );

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  const [items, setItems] = useState<ItemType[]>([]);
  const [isConnected, setIsConnected] = useState(false);

  const updateConversationDuration = useCallback(() => {
    if (!isWingmanEnabled) return '0m 0s';
    if (!isConnected) return '0m 0s';
    if (!conversationStartTime) return '0m 0s';
    
    const now = new Date();
    const durationMs = now.getTime() - conversationStartTime.getTime();
    const minutes = Math.floor(durationMs / 60000);
    const seconds = Math.floor((durationMs % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  }, [conversationStartTime, isWingmanEnabled, isConnected]);

  // Update conversation duration every second
  useEffect(() => {
    if (!isWingmanEnabled) return;

    const timer = setInterval(() => {
      setConversationDuration(updateConversationDuration());
    }, 1000);

    return () => clearInterval(timer);
  }, [isWingmanEnabled, updateConversationDuration]);

  const retryAnalysis = async (error: any) => {
    if (retryAttemptsRef.current < MAX_RETRY_ATTEMPTS) {
      retryAttemptsRef.current++;
      
      let errorMessage = ANALYSIS_MESSAGES.API_ERROR;
      if (error.message?.includes('rate limit')) {
        errorMessage = ANALYSIS_MESSAGES.RATE_LIMIT;
      } else if (error.message?.includes('network')) {
        errorMessage = ANALYSIS_MESSAGES.NETWORK_ERROR;
      }

      setAnalysisResult({
        nextResponses: [errorMessage],
        confidence: 5
      });

      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return analyzeConversation();
    }
    
    // Max retries reached
    setAnalysisResult({
      nextResponses: [ANALYSIS_MESSAGES.UNAVAILABLE],
      confidence: 5
    });
    
    // Reset retry counter after some time to allow future attempts
    setTimeout(() => {
      retryAttemptsRef.current = 0;
    }, ANALYSIS_INTERVAL);
  };

  const analyzeConversation = async () => {
    if (!isWingmanEnabled) return;

    try {
      const last20Items = itemsRef.current.slice(-20);
      const userMessages = last20Items
        .filter(item => item.role === 'assistant')
        .map(item => ({
          content: item.formatted.text || item.formatted.transcript || ''
        }));

      // If no messages yet, provide a welcoming initial state
      if (userMessages.length === 0) {
        setAnalysisResult({
          nextResponses: ["Click the microphone icon and start the conversation with a friendly hello! I'm here to help guide you through the interaction."],
          confidence: 1 // Set low confidence when no conversation yet
        });
        return;
      }

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getApiKey()}`
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            {
              role: 'system',
              content: `You are an expert dating and pickup coach analyzing conversation quality. Rate specifically the conversation of the Speaker based on these criteria:

1. Natural Flow (0-3 points):
- Does the conversation flow naturally without awkward transitions?
- Are responses contextually appropriate?

2. Engagement Level (0-3 points):
- Is there back-and-forth dialogue?
- Are both parties showing interest?

3. Emotional Connection (0-2 points):
- Is there rapport building?
- Are emotional cues being picked up and responded to?

4. Conversation Progress (0-2 points):
- Is the conversation moving forward?
- Are new topics being explored appropriately?

Sum these points for the final confidence score (1-10).

Based on the AI's responses in this conversation, suggest three different natural next topics with dry humor, some emojis if appropriate  and provide the confidence score. Each response should take a slightly different approach to keep the conversation engaging.
Return JSON: {"nextResponses": string[], "confidence": number}`
            },
            {
              role: 'user',
              content: JSON.stringify(userMessages)
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();
      const result = JSON.parse(data.choices[0].message.content);
      
      // Apply conversation length factor
      let adjustedConfidence = result.confidence;
      if (userMessages.length < MIN_MESSAGES_FOR_HIGH_CONFIDENCE) {
        // Cap confidence at 7 for short conversations
        adjustedConfidence = Math.min(adjustedConfidence, 7);
      }
      
      // Reset retry counter on successful analysis
      retryAttemptsRef.current = 0;
      
      setAnalysisResult({
        nextResponses: result.nextResponses,
        confidence: adjustedConfidence
      });
    } catch (error) {
      console.error('Failed to analyze conversation:', error);
      retryAnalysis(error);
    }
  };

  // Effect for handling wingman mode toggle
  useEffect(() => {
    if (isWingmanEnabled) {
      // Initial analysis
      analyzeConversation();
      
      // Set up the interval for subsequent analyses
      analysisIntervalRef.current = setInterval(analyzeConversation, ANALYSIS_INTERVAL);
      
      // Initialize conversation duration display
      setConversationDuration('0m 0s');
    } else {
      // Clean up when wingman is disabled
      if (analysisIntervalRef.current) {
        clearInterval(analysisIntervalRef.current);
        analysisIntervalRef.current = null;
      }
      setAnalysisResult(null);
      setConversationStartTime(null);
      setConversationDuration('0m 0s');
      hasFirstResponseRef.current = false;
    }

    // Cleanup on unmount or when wingman is toggled off
    return () => {
      if (analysisIntervalRef.current) {
        clearInterval(analysisIntervalRef.current);
        analysisIntervalRef.current = null;
      }
    };
  }, [isWingmanEnabled]);

  // Effect for updating itemsRef
  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const resetAPIKey = useCallback(() => {
    window.location.reload();
  }, []);

  const toggleChatVisibility = useCallback(() => {
    setIsChatVisible(prev => !prev);
  }, []);

  const initializeAudioContext = useCallback(async () => {
    if (!audioContextInitialized) {
      try {
        const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
        const audioContext = new AudioContext();
        await audioContext.resume();
        setAudioContextInitialized(true);
      } catch (error) {
        console.error('Failed to initialize audio context:', error);
      }
    }
  }, [audioContextInitialized]);

  const connectConversation = useCallback(async () => {
    await initializeAudioContext();
    
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    setIsConnected(true);
    setItems(client.conversation.getItems());
    setConversationStartTime(new Date());

    try {
      await wavRecorder.begin();
      await wavStreamPlayer.connect();
      await client.connect();
      
      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: `Hello!`,
        },
      ]);

      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
    } catch (error) {
      console.error('Failed to connect:', error);
      setIsConnected(false);
      setConversationStartTime(null);
    }
  }, [initializeAudioContext]);

  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);
    setConversationStartTime(null);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    let isLoaded = true;

    changeTurnEndType('server_vad');

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#FFC0CB',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    client.updateSession({ instructions: instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    // @ts-ignore - Suppress the type error for voice
    client.updateSession({ voice: getRandomVoice() });

    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      
      // Set conversation start time on first AI response
      if (isWingmanEnabled && item.role === 'assistant' && !hasFirstResponseRef.current) {
        hasFirstResponseRef.current = true;
      }
      
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, []);

  return (
    <div data-component="VoiceChat" className={isWingmanEnabled ? 'chat-hidden' : ''}>
      <div className="content-main">
        <div className="content-logs">
          <div className="controls">
            <div className="content-title">
              <span>Dating Voice Simulator</span>
            </div>
            <div className="wingman-toggle">
              <label>
                <input
                  type="checkbox"
                  checked={isWingmanEnabled}
                  onChange={(e) => setIsWingmanEnabled(e.target.checked)}
                />
                Coaching help Mode
              </label>
            </div>
            {isWingmanEnabled && <WingmanAnalysis result={analysisResult} duration={conversationDuration} />}
            <div className="content-block events">
              <div className="visualization">
                <div className="visualization-entry client">
                  <canvas ref={clientCanvasRef} />
                </div>
                <div className="visualization-entry server">
                  <canvas ref={serverCanvasRef} />
                </div>
                <div 
                  className={`mic-button ${isConnected ? 'active' : ''}`}
                  onClick={isConnected ? disconnectConversation : connectConversation}
                >
                  <Mic size={48} />
                </div>
              </div>
            </div>
          </div>
          
          <div className={`content-block conversation ${!isWingmanEnabled ? 'show' : ''}`}>
            <div className="content-block-body" data-conversation-content>
              {items.map((conversationItem, i) => {
                return (
                  <div
                    className="conversation-item"
                    key={conversationItem.id}
                  >
                    <div className={`speaker ${conversationItem.role || ''}`}>
                      <div>
                        {(
                          conversationItem.role === 'user'
                            ? 'You'
                            : conversationItem.role === 'assistant'
                            ? 'AI'
                            : conversationItem.role || conversationItem.type
                        ).replaceAll('_', ' ')}
                      </div>
                      <div
                        className="close"
                        onClick={() =>
                          deleteConversationItem(conversationItem.id)
                        }
                      >
                        <X />
                      </div>
                    </div>
                    <div className={`speaker-content`}>
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                  '(item sent)')}
                          </div>
                        )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              '(truncated)'}
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div style={{position: 'fixed', bottom: '10px', right: '10px', fontSize: '12px', color: '#666'}}>
        MAY@02112024
      </div>
    </div>
  );
}

import React, { useState, useEffect, Suspense } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { motion, AnimatePresence } from 'framer-motion';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import './LandingPage.scss';

const LazyYouTubePlayer = React.lazy(() => import('../../components/YouTubePlayer'));

const ShimmerEffect = () => (
  <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-shimmer"></div>
);

const STORAGE_KEY = 'waitingList';
const AIRTABLE_TOKEN = 'pat1yUrY1GKMUuX0k.26a680e59e83ef9e8017e304617e4aa1031448486222de03df20e6dbd8dcebb0';
const AIRTABLE_BASE = 'app7kesDeH7bJkMIt';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const shimmerInterval = setInterval(() => {
      setShowShimmer(true);
      setTimeout(() => setShowShimmer(false), 2000);
    }, 10000);

    return () => clearInterval(shimmerInterval);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormError('');
    setIsSubmitting(true);
    
    if (!firstName.trim()) {
      setFormError('Please enter your first name.');
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(email)) {
      setFormError('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    try {
      const entry = {
        firstName: firstName.trim(),
        email: email.toLowerCase().trim(),
        timestamp: new Date().toISOString()
      };

      const response = await fetch(`https://api.airtable.com/v0/${AIRTABLE_BASE}/Table%201`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${AIRTABLE_TOKEN}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          records: [{
            fields: {
              'Name': entry.firstName,
              'E-Mail': entry.email,
              'Notes': `Signed up on ${entry.timestamp}`
            }
          }]
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Airtable error:', errorData);
        throw new Error(errorData.error?.message || 'Failed to save to Airtable');
      }

      localStorage.setItem(STORAGE_KEY, JSON.stringify([
        ...JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]'),
        entry
      ]));
      
      setFormSuccess(true);
      setFirstName('');
      setEmail('');
    } catch (error) {
      console.error('Error saving lead:', error);
      setFormError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="landing-page">
      <div className="landing-page__gradient-bg" />

      <header className="landing-page__header">
        <div className="landing-page__header-container">
          <div className="landing-page__header-logo group">
            {showShimmer && <ShimmerEffect />}
            <img src="/images/logo.png" alt="AI Voice Dating Simulator Logo" className="logo-image" />
            <h1>AI Voice Dating Simulator</h1>
          </div>
        </div>
      </header>

      <motion.main 
        className="landing-page__main"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.section 
          className="landing-page__hero"
          variants={itemVariants}
        >
          <h2>AI-Powered Dating Voice Simulator</h2>
        </motion.section>

        <motion.div 
          className="landing-page__content"
          variants={itemVariants}
        >
          <section className="landing-page__video">
            <Suspense fallback={<div className="video-placeholder" />}>
              <LazyYouTubePlayer />
            </Suspense>
          </section>

          <section className="landing-page__signup">
            <Card className="signup-card">
              <CardHeader>
                <CardTitle>
                  <span className="waiting-list-title">Join the Waiting List</span>
                </CardTitle>
                <div className="card-description">
                  <div className="social-proof">
                    <span className="social-proof-text">The first 50 spots are already filled! Enter your name and email below to secure your place on the waiting list. There's no commitment, and you can unsubscribe from our emails anytime.</span>
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="form-container">
                  <div className="form-grid">
                    <Input 
                      type="text" 
                      placeholder="First Name" 
                      className="form-input"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={isSubmitting}
                      aria-label="Enter your first name"
                    />
                    <Input 
                      type="email" 
                      placeholder="Email" 
                      className="form-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={isSubmitting}
                      aria-label="Enter your email address"
                    />
                    <Button 
                      type="submit" 
                      className="form-button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Joining...' : 'Join Waiting List'}
                    </Button>
                  </div>
                  
                  {formError && (
                    <Alert className="bg-red-900/50 border-red-600">
                      <AlertDescription>{formError}</AlertDescription>
                    </Alert>
                  )}
                  
                  {formSuccess && (
                    <Alert className="bg-green-900/50 border-green-600">
                      <AlertDescription>
                        <div className="success-message">
                          <Check className="success-icon" aria-hidden="true" />
                          <span>You're on the list! We'll contact you when we launch.</span>
                        </div>
                      </AlertDescription>
                    </Alert>
                  )}

                  <div className="privacy-notice-trigger">
                    <button 
                      type="button"
                      onClick={() => setShowPrivacy(!showPrivacy)}
                      className="text-purple-400 hover:text-purple-300 underline"
                    >
                      Show Privacy Notice
                    </button>
                  </div>

                  <AnimatePresence>
                    {showPrivacy && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="privacy-notice"
                      >
                        <h5>Privacy Notice:</h5>
                        <div className="privacy-content">
                          We store your email address and first name to keep you informed about 
                          the Voice Dating Simulator launch. Your data is encrypted and never shared 
                          with third parties. You can withdraw your consent at any time by emailing 
                          privacy@dating-voice-simulator.com.
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </form>
              </CardContent>
            </Card>
          </section>
        </motion.div>
      </motion.main>

      <footer className="landing-page__footer">
        <div className="footer-content">
          <div className="footer-text">Thank You for Supporting Us</div>
          <div className="footer-info">
            <div className="info-section">
              <address className="company-info">
                Filedesign Informatik<br />
                Holeeholzweg 69<br />
                CH-4102 Binningen<br />
                Tel. +41 61 422 oo oo<br />
                kontakt [at] filedesign [dot] com
              </address>
              <div className="legal-links">
                <Link to="/">Home</Link> • <Link to="/imprint">Imprint</Link> • <Link to="/privacy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;

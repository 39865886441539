// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #18181b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;EACV,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,oCAAoC;EACpC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["html,\nbody {\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  min-height: 100%;\n  font-family: 'Assistant', sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n  color: #18181b;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow-x: hidden;\n}\n\n#root {\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

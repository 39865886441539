import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../LandingPage/LandingPage.scss';

const Privacy = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <div className="landing-page">
      <div className="landing-page__gradient-bg" />

      <header className="landing-page__header">
        <div className="landing-page__header-container">
          <div className="landing-page__header-logo group">
            <Link to="/">
              <img src="/images/logo.png" alt="AI Voice Dating Simulator Logo" className="logo-image" />
            </Link>
            <h1>Dating Voice Simulator</h1>
          </div>
        </div>
      </header>

      <motion.main 
        className="landing-page__main"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <section className="content-section">
          <h2>Privacy Policy</h2>
          
          <div className="content-block">
            <h3>1. Data Protection Overview</h3>
            <p>We at Dating Voice Simulator take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with statutory data protection regulations and this privacy policy.</p>
          </div>

          <div className="content-block">
            <h3>2. Data Collection</h3>
            <p>We collect the following data:</p>
            <ul>
              <li>First name</li>
              <li>Email address</li>
              <li>Usage data for service improvement</li>
              <li>Voice recordings during simulator usage</li>
            </ul>
          </div>

          <div className="content-block">
            <h3>3. Purpose of Data Collection</h3>
            <p>We use your data to:</p>
            <ul>
              <li>Provide and improve our AI voice dating simulation service</li>
              <li>Send you important updates about the service</li>
              <li>Analyze and improve conversation patterns</li>
              <li>Provide personalized feedback and coaching</li>
            </ul>
          </div>

          <div className="content-block">
            <h3>4. Your Rights</h3>
            <p>You have the following rights:</p>
            <ul>
              <li>Right to access your personal data</li>
              <li>Right to rectification of incorrect data</li>
              <li>Right to erasure ("right to be forgotten")</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
            </ul>
          </div>

          <div className="content-block">
            <h3>5. Data Security</h3>
            <p>We implement appropriate technical and organizational security measures to protect your personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.</p>
          </div>

          <div className="content-block">
            <h3>6. Contact for Privacy Matters</h3>
            <p>For any privacy-related questions or concerns, please contact us at:<br />
            privacy@dating-voice-simulator.com</p>
          </div>

          <div className="content-block">
            <h3>7. Updates to Privacy Policy</h3>
            <p>We may update this privacy policy from time to time. The latest version will always be available on this page.</p>
            <p>Last updated: January 20, 2024</p>
          </div>
        </section>
      </motion.main>

      <footer className="landing-page__footer">
        <div className="footer-content">
          <div className="footer-info">
            <div className="info-section">
              <div className="legal-links">
                <Link to="/">Home</Link> • <Link to="/imprint">Imprint</Link> • <Link to="/privacy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;

import React, { useEffect, useState, useRef } from 'react';
import ProgressBar from './ProgressBar';
import SuggestedResponse from './SuggestedResponse';
import './WingmanAnalysis.scss';

interface AnalysisResult {
  nextResponses: string[];
  confidence: number;
}

interface Props {
  result: AnalysisResult | null;
  duration: string;
}

export const WingmanAnalysis: React.FC<Props> = ({ result, duration }) => {
  // Convert confidence score (1-10) to percentage (0-100)
  const qualityPercentage = result ? result.confidence * 10 : 0;

  // Get quality indicator class based on confidence
  const getQualityClass = () => {
    if (!result) return '';
    if (result.confidence >= 8) return 'excellent';
    if (result.confidence >= 6) return 'good';
    if (result.confidence >= 4) return 'fair';
    return 'poor';
  };

  // Get quality description based on confidence
  const getQualityDescription = () => {
    if (!result) return '';
    if (result.confidence >= 8) return 'Excellent flow and engagement';
    if (result.confidence >= 6) return 'Good progress, keep it up';
    if (result.confidence >= 4) return 'Fair, room for improvement';
    return 'Needs work on engagement';
  };

  const qualityClass = getQualityClass();

  return (
    <div className="wingman-analysis">
      <div className={`analysis-card ${qualityClass}`}>
        {result ? (
          <>
            <div className="analysis-header">
              <span className="duration">Dating time: {duration}</span>
              <span className="quality-description">{getQualityDescription()}</span>
            </div>
            <div className="quality-indicator">
              <ProgressBar 
                trait="Conversation Quality" 
                percentage={qualityPercentage}
                qualityClass={qualityClass as 'excellent' | 'good' | 'fair' | 'poor'}
              />
            </div>
            <div className="suggestion">
              <h4>Try saying one of these:</h4>
              {result.nextResponses.map((response, index) => (
                <SuggestedResponse 
                  key={index}
                  index={index}
                  response={response}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="waiting-state">
            <p>Waiting for first analysis...</p>
            <div className="quality-indicator">
              <ProgressBar 
                trait="Conversation Quality" 
                percentage={0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  margin-bottom: 15px;
}
.progress-bar .progress-bar-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
  color: #888;
}
.progress-bar .progress-bar-container {
  height: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.progress-bar .progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease;
}
.progress-bar .progress-bar-fill.excellent {
  background-color: #4CAF50;
}
.progress-bar .progress-bar-fill.good {
  background-color: #2196F3;
}
.progress-bar .progress-bar-fill.fair {
  background-color: #FFC107;
}
.progress-bar .progress-bar-fill.poor {
  background-color: #f44336;
}
.progress-bar .progress-bar-percentage {
  text-align: right;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AACJ;AAEE;EACE,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,YAAA;EACA,2BAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAII;EACE,yBAAA;AAFN;AAKI;EACE,yBAAA;AAHN;AAMI;EACE,yBAAA;AAJN;AAQE;EACE,iBAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;AANJ","sourcesContent":[".progress-bar {\n  margin-bottom: 15px;\n\n  .progress-bar-label {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 5px;\n    font-size: 14px;\n    color: #888;\n  }\n\n  .progress-bar-container {\n    height: 10px;\n    background-color: rgba(255, 255, 255, 0.1);\n    border-radius: 5px;\n    overflow: hidden;\n  }\n\n  .progress-bar-fill {\n    height: 100%;\n    transition: width 0.3s ease;\n    \n    &.excellent {\n      background-color: #4CAF50;\n    }\n\n    &.good {\n      background-color: #2196F3;\n    }\n\n    &.fair {\n      background-color: #FFC107;\n    }\n\n    &.poor {\n      background-color: #f44336;\n    }\n  }\n\n  .progress-bar-percentage {\n    text-align: right;\n    font-size: 12px;\n    color: #666;\n    margin-top: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
